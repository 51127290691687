import Auth from '@/middlewares/auth'

export default [
  {
    name: 'Announcements',
    path:  '/announcement',
    component: () => import('@/pages/announcement/Announcement'),
    meta: {
      title: 'servinga Cloud - Announcements',
      middleware: Auth,
      auth: true
    }
  },
  {
    name: 'AnnouncementDetails',
    path:  '/announcement/:id',
    component: () => import('@/pages/announcement/AnnouncementDetails'),
    meta: {
      title: 'servinga Cloud - Announcement Details',
      middleware: Auth,
      auth: true
    }
  }
];
