import Auth from '@/middlewares/auth'

export default [
  {
    path: '/',
    name:'Dashboard',
    component: () => import('@/pages/dashboard/Dashboard'),
    meta: {
      title: 'servinga Cloud - Dashboard',
      middleware: Auth,
      auth: true
    }
  },
]
