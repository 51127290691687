import {
  fetchServerCreateAdditionalDisk,
  fetchServerCancelAdditionalDisk,
  fetchServerDiskPrice,
  fetchDiskResizePrice,
  fetchUpdateDiskSize
} from '@/services/service/server'

const initialState = {
  isCreated: false,
  createErrors: [],
  resizeErrors: [],
  diskPricing: null,
  diskPricingError: [],
  isCancelledAdditionalDisk: false,
  isResized: false,
}

export default {
  state: initialState,
  getters: {
    isDiskCreated: (state) => state.isCreated,
    isDiskResized: (state) => state.isResized,
    diskCreateErrors: (state) => state.createErrors,
    diskResizeErrors: (state) => state.resizeErrors,
    diskPricing: (state) => state.diskPricing,
    diskPricingError: (state) => state.diskPricingError,
    isCancelledAdditionalDisk: (state) => state.isCancelledAdditionalDisk
  },
  mutations: {
    setInitialState(state) {
      state.isCreated = false
      state.createErrors = []
      state.resizeErrors = []
      state.diskPricing = {}
      state.diskPricingError = []
      state.isCancelledAdditionalDisk = false
      state.isResized = false
    },
    setAdditionalDiskIsCancelled(state) {
      state.isCancelledAdditionalDisk = true
    },
    setDiskPrice(state, payload) {
      state.diskPricing = payload
    },
    setDiskPriceError(state, payload) {
      state.diskPricingError = payload
    },
    setCreatedDisk(state) {
      state.isCreated = true
    },
    setCreatedDiskError(state, payload) {
      state.createErrors = payload
    },
    setResizeDiskError(state, payload) {
      state.resizeErrors = payload
    },
    setResizedDisk(state) {
      state.isResized = true
    }
  },
  actions: {
    async createAdditionalDisk({commit, state}, form) {
      const previousPricing = state.diskPricing // Store the previous pricing
      commit('setInitialState')
      commit('setDiskPrice', previousPricing)

      const response = await fetchServerCreateAdditionalDisk(form)

      if (response && response.status === 201) {
        commit('setCreatedDisk')
      } else if (response) {
        commit('setCreatedDiskError', response.data)
      }
    },
    async cancelAdditionalDisk({commit}, form) {
      commit('setInitialState')

      const response = await fetchServerCancelAdditionalDisk(form)

      if (response && response.status === 200) {
        commit('setAdditionalDiskIsCancelled')
      }
    },
    async calculateDiskPrice({commit}, form) {
      const response = await fetchServerDiskPrice(form)

      if (response && response.status === 200) {
        commit('setDiskPrice', response.data)
      } else if (response) {
        commit('setDiskPriceError', response.data)
      }
    },
    async getDiskResizePrice({commit}, form) {
      const response = await fetchDiskResizePrice(form)

      if (response && response.status === 200) {
        commit('setDiskPrice', response.data)
      } else if (response) {
        commit('setDiskPriceError', response.data)
      }
    },
    async updateDiskSize({commit}, form) {
      const response = await fetchUpdateDiskSize(form)

      if (response && response.status === 200) {
        commit('setResizedDisk')
      } else if (response) {
        commit('setResizeDiskError', response.data)
      }
    }
  },
}
