import Referral from '@/middlewares/referral'

export default [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/pages/auth/Login'),
    meta: {
      title: 'servinga Cloud - Login',
      auth: false
    }
  },
  {
    name: 'Completion',
    path: '/login/complete-profile',
    component: () => import('@/pages/auth/ProfileCompletion'),
    meta: {
      title: 'servinga Cloud - Profile Completion',
      auth: false
    }
  },
  {
    path: '/register/:referral?',
    name: 'Register',
    component: () => import('@/pages/auth/Register'),
    meta: {
      title: 'servinga Cloud - Sign Up',
      middleware: Referral,
      auth: false
    }
  },
  {
    path: '/password/reset/:token',
    name: 'Reset-Password',
    component: () => import('@/pages/auth/ResetPassword'),
    meta: {
      title: 'servinga Cloud - Reset Password',
      auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot-Password',
    component: () => import('@/pages/auth/ForgotPassword'),
    meta: {
      title: 'servinga Cloud - Forgot Password',
      auth: false
    }
  },
  {
    path: '/verify',
    component: () => import('@/pages/auth/VerifyCustomer'),
    meta: {
      title: 'servinga Cloud - Verify Your Account',
      auth: true
    }
  },
  {
    path: '/2fa-verify',
    name: 'TwoFa-Verify',
    component: () => import('@/pages/auth/TwoFaVerifyCustomer'),
    meta: {
      title: 'servinga Cloud - Two Factor Verify Your Account',
      auth: false
    }
  },
  {
    path: '/migrate',
    name: 'Migrate',
    component: () => import('@/pages/auth/Migrate'),
    meta: {
      title: 'servinga Cloud - Migrate Your Account',
      auth: true
    }
  },
  {
    path: '/migrate/twoFa',
    name: 'MigrateTwoFa',
    component: () => import('@/pages/auth/MigrateTwoFa'),
    meta: {
      title: 'servinga Cloud - Migrate Your Account',
      auth: true
    }
  },
  {
    path: '/migrate/final',
    name: 'MigrateFinal',
    component: () => import('@/pages/auth/MigrateFinal'),
    meta: {
      title: 'servinga Cloud - Migrate Your Account',
      auth: true
    }
  }
];
